// Import necessary hooks and components
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Stack,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  NotInterested as NotInterestedIcon,
  DeleteOutline as DeleteOutlineIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { MdOutlineAddLink } from 'react-icons/md';
import DeleteMedicationModal from './DeleteMedicationModal';
import MedicationModal from './MedicationModal';
import StopMedicationModal from './StopMedicationModal';

const MedicationActions = (props) => {
  const { medications, selected, setSelected, isHistory } = props;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [stopModalOpen, setStopModalOpen] = useState(false);
  const { t } = useTranslation();
  const { edit } = useSelector((state) => state.permissions);
  const { access } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { uuid } = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const selectedMeds = useMemo(() => {
    if (medications && selected) {
      return medications.filter((it) => selected.includes(it.uuid));
    } else {
      return [];
    }
  }, [medications, selected]);

  const enabled = access === 'PT';

  const handleEditClick = () => {
    setAddModalOpen(true);
  };

  const handleCreatePrescription = () => {
    navigate(`/patient-profile/${uuid}/prescriptions/new`, {
      state: { medications: selectedMeds },
    });
  };

  const actions = [
    {
      label: t('Add'),
      onClick: () => setAddModalOpen(true),
      icon: <AddIcon />,
      disabled: !enabled || !edit,
      show: !isHistory,
      variant: 'contained',
    },
    {
      label: t('Edit'),
      onClick: handleEditClick,
      icon: <EditIcon />,
      disabled: !enabled || selectedMeds.length !== 1 || !edit,
      show: !isHistory,
      variant: 'outlined',
    },
    {
      label: t('Stop'),
      onClick: () => setStopModalOpen(true),
      icon: <NotInterestedIcon />,
      disabled: !enabled || selectedMeds.length === 0 || !edit,
      show: !isHistory,
      variant: 'outlined',
    },
    {
      label: t('Create Prescription'),
      onClick: handleCreatePrescription,
      icon: <MdOutlineAddLink />,
      disabled:
        !enabled ||
        selectedMeds.length === 0 ||
        !edit,// ||
        // selectedMeds.some((med) => med.has_request_orchestration),
      show: !isHistory,
      variant: 'contained',
    },
    {
      label: t('Delete'),
      onClick: () => setDeleteModalOpen(true),
      icon: <DeleteOutlineIcon />,
      disabled: !enabled || selectedMeds.length === 0,
      show: true,
      variant: 'outlined',
      color: 'error',
      sx: { borderColor: 'error.main', color: 'error.main' },
    },
  ];

  return (
    <Toolbar disableGutters variant="dense" data-cy="toolbar">
      {isMobile ? (
        <>
          <IconButton
            aria-label="more"
            aria-controls="action-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="action-menu"
            anchorEl={anchorEl}
            keepMounted
            open={openMenu}
            onClose={handleMenuClose}
          >
            {actions
              .filter((action) => action.show)
              .map((action, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    action.onClick();
                    handleMenuClose();
                  }}
                  disabled={action.disabled}
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText primary={action.label} />
                </MenuItem>
              ))}
          </Menu>
        </>
      ) : (
        <Stack spacing={1} direction="row" sx={{ ml: 1 }}>
          {actions
            .filter((action) => action.show)
            .map((action, index) => (
              <Button
                key={index}
                variant={action.variant}
                color={action.color || 'primary'}
                disableElevation
                size="small"
                disabled={action.disabled}
                onClick={action.onClick}
                startIcon={action.icon}
                sx={action.sx}
              >
                {action.label}
              </Button>
            ))}
        </Stack>
      )}
      {/* Modals */}
      <MedicationModal
        open={addModalOpen}
        selectedId={selected[0]}
        handleClose={() => {
          setAddModalOpen(false);
          setSelected([]);
        }}
      />
      {stopModalOpen && (
        <StopMedicationModal
          open={stopModalOpen}
          selectedMeds={selectedMeds}
          handleClose={() => {
            setStopModalOpen(false);
            setSelected([]);
          }}
        />
      )}
      <DeleteMedicationModal
        open={deleteModalOpen}
        handleClose={() => {
          setDeleteModalOpen(false);
          setSelected([]);
        }}
        selectedMedications={selectedMeds}
      />
    </Toolbar>
  );
};

export default MedicationActions;
