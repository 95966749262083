import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { languages } from '../../helpers/languagesConfig';
import { greyboxApiActions } from '../../redux/api';
import FormikPhoneField from '../form-inputs/FormikPhoneField';
import FormikSelect from '../form-inputs/FormikSelect';
import FormikTextField from '../form-inputs/FormikTextField';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Box, Button, Container, IconButton, MenuItem, Paper, Skeleton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '@mui/lab/LoadingButton';
import { Identifiers } from '../form-inputs/Identifiers';

const EditAccountPage = () => {
  const { t } = useTranslation();
  const { account, identifierConfig } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { data = {}, isFetching, isError } = account.get(uuid);
  const [updateAccount] = account.update();

  const { data: identifiers } = identifierConfig.list({
    clinic: clinic?.id,
    reference_fhir_fields: data.acc_type === 'PT' ? ['Practitioner.identifier'] : ['Patient.identifier'],
  });

  if (isFetching) {
    return (
      <Container maxWidth="md">
        <Skeleton variant="text" width="40%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={400} />
      </Container>
    );
  }

  if (isError) {
    navigate(-1);
    return null;
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h1">
          {t('Edit Account')}
        </Typography>
      </Box>
      <Formik
        initialValues={{
          user: {
            first_name: data.user?.first_name || '',
            last_name: data.user?.last_name || '',
            email: data.user?.email || '',
            mobile: data.user?.mobile || '',
            language: data.user?.language || 'en',
          },
          identifier: data.identifier || [{ system: '', value: '' }],
        }}
        enableReinitialize
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={yup.object().shape({
          user: yup.object().shape({
            first_name: yup.string().required(t('required')),
            last_name: yup.string().required(t('required')),
            email: yup.string().email(t('Invalid email')),
            mobile: yup.string(),
            language: yup.string().required(t('required')),
          }),
          identifier: Identifiers.validationSchema,
        })}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          updateAccount({ id: uuid, body: values })
            .unwrap()
            .then(() => {
              navigate(-1);
            })
            .catch((error) => {
              setSubmitting(false);
              if (error.status === 400 && error.data) {
                const errors = error.data;
                setErrors(errors);
              }
            });
        }}
      >
        {(formik) => (
          <Paper sx={{ p: 3 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr 1fr', xs: '1fr' },
                    gap: 2,
                  }}
                >
                  <FormikTextField
                    required
                    name="user.first_name"
                    label={t('First name')}
                  />
                  <FormikTextField
                    required
                    name="user.last_name"
                    label={t('Last name')}
                  />
                  <FormikTextField
                    required
                    name="user.email"
                    label={t('Email')}
                    type="email"
                  />
                  <FormikPhoneField
                    name="user.mobile"
                    label={t('Phone Number')}
                  />
                  <FormikSelect name="user.language" label={t('Language')}>
                    {languages.map((language) => (
                      <MenuItem key={language.code} value={language.code}>
                        {t(language.labelKey)}
                      </MenuItem>
                    ))}
                  </FormikSelect>
                </Box>
                <Identifiers informations={formik} clinic={clinic} role={data.acc_type} identifiers={identifiers} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: 3,
                }}
              >
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => formik.resetForm()}
                  sx={{ mr: 2 }}
                >
                  {t('Reset')}
                </Button>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={formik.isSubmitting || !formik.dirty}
                  loading={formik.isSubmitting}
                >
                  {t('Save')}
                </LoadingButton>
              </Box>
            </form>
          </Paper>
        )}
      </Formik>
    </Container>
  );
};

export default EditAccountPage;
