import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../redux/api';

const GenerateReportDialog = (props) => {
  const { open, handleClose, patientUuid, resource, setOpenGeneratedReportMessage, setSnackbar, setIframeUrl } = props;
  const { i18n, t } = useTranslation();
  const [reportType, setReportType] = useState('');
  const { patientReport, patientReportClinic } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const [postReport, result] = patientReport.add();
  const { data } = patientReportClinic.list({ clinic: clinic.id, role: 'clinical_note' });

  const handleSubmit = async () => {
    const body = {
      type: 'pdf',
      report_clinic: reportType,
      patient: patientUuid,
      language: i18n.resolvedLanguage,
      data: {
        params_values: {
          "clinical-impression": resource.id
        }
      }
    };
    try {
      const response = await postReport({ body });
      if (response.data && response.data.file) {
        setIframeUrl(response.data.file);
        setSnackbar({ message: 'Report generated successfully!', severity: 'success' });
        setOpenGeneratedReportMessage(true);
      } else {
        setSnackbar({ message: 'Report generated successfully!', severity: 'success' });
        setOpenGeneratedReportMessage(true);
      }
    } catch (error) {
      console.error('Error generating report:', error);
      setSnackbar({ message: 'Failed to generate report. Please try again.', severity: 'error' });
      setOpenGeneratedReportMessage(true);
    }
    handleClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogContent>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            {t('Select Report Type')}
          </Typography>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="report-type-label">{t('Report Type')}</InputLabel>
            <Select
              labelId="report-type-label"
              id="report-type-select"
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
              label={t('Report Type')}
            >
              {data?.map((report) => (
                <MenuItem key={report.id} value={report.id}>
                  {report.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={result.isLoading}>
            {t('Cancel')}
          </Button>
          <LoadingButton onClick={handleSubmit} loading={result.isLoading}>
            {t('Generate')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenerateReportDialog;
