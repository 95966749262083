import { Box, Toolbar, useMediaQuery } from '@mui/material';
import React from 'react';
import AppBarLogo from './AppBarLogo';

const AppBarUnregistered = ({ children, layoutMode }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isMinimal = layoutMode === 'minimal';
  const isLogoOnly = layoutMode === 'logo-only';

  return (
    <Box
      display="flex"
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
        ...(isMinimal && { flexDirection: 'column' }),
      }}
    >
      {isLogoOnly && (
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.background.paper,
            width: '100%',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: 'fixed',
          }}
        >
          <Toolbar sx={{ height: 60 }}>{!isMobile && <AppBarLogo />}</Toolbar>
        </Box>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: isLogoOnly ? '64px' : 0,
          width: isLogoOnly ? 'calc(100% - 245px)' : '100%',
          overflow: 'auto',
          bgcolor: (theme) => theme.palette.background.default,
          px: isLogoOnly ? '1rem' : 0,
          pt: isLogoOnly ? 3 : 0,
          minHeight: isLogoOnly ? 'calc(100vh - 90px)' : '100vh',
          borderTopLeftRadius: isLogoOnly ? '20px' : 0,
          borderTopRightRadius: isLogoOnly ? '20px' : 0,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AppBarUnregistered;
