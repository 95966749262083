import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../../redux/api';
import { QuestionnaireManagementClinicType } from '../../../types';
import Row from '../../Row';
import {
  FormSelect, FormTextField, LocaleWrapper, Diagnosis,
  Recurrence, RichFormText, RoleTypes,
} from '../../form-inputs';
import { parseQuestionnaireValuesForPost } from './utils';
import DeleteDialog from '../../DeleteDialog';
import { ThresholdTextField } from '../../form-inputs/Thresholds';

const QuestionnaireEdit = ({ data }: { data: QuestionnaireManagementClinicType }) => {
  const { t } = useTranslation();
  const { questionnaireManagementClinic } = greyboxApiActions;
  const { id } = useParams<{ id: string }>();
  const [updateQuestionnaire] = questionnaireManagementClinic.update();
  const [deleteQuestionnaire] = questionnaireManagementClinic.delete();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [showRecurrence, setShowRecurrence] = React.useState(false);
  const [advancedMode, setAdvancedMode] = React.useState(false);
  const readOnly = !(data?.permissions.includes('change_questionnaireclinic_management'));
  const navigate = useNavigate();

  const handleDelete = async () => {
    await deleteQuestionnaire(id);
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: {
      name_en: data?.questionnaire?.name_en,
      name_fr: data?.questionnaire?.name_fr,
      name_de: data?.questionnaire?.name_de,
      name_es: data?.questionnaire?.name_es,
      name_it: data?.questionnaire?.name_it,
      name_pt: data?.questionnaire?.name_pt,
      description_en: data?.questionnaire?.description_en,
      description_fr: data?.questionnaire?.description_fr,
      description_de: data?.questionnaire?.description_de,
      description_es: data?.questionnaire?.description_es,
      description_it: data?.questionnaire?.description_it,
      description_pt: data?.questionnaire?.description_pt,
      max_score: data?.questionnaire?.max_score || '',
      score_type: data?.questionnaire?.score_type,
      on_demand: data?.on_demand || false,
      active: data?.active || false,
      is_dashboard: data?.is_dashboard || false,
      type: data?.questionnaire?.type,
      diagnoses: data?.diagnoses || [],
      recurrences: data?.recurrences,
      role_acc_type: data?.role_acc_type || [],
      min_high_threshold: data?.questionnaire?.min_high_threshold,
      max_high_threshold: data?.questionnaire?.max_high_threshold,
      min_mod_threshold: data?.questionnaire?.min_mod_threshold,
      max_mod_threshold: data?.questionnaire?.max_mod_threshold,
      min_low_threshold: data?.questionnaire?.min_low_threshold,
      max_low_threshold: data?.questionnaire?.max_low_threshold,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      texts: Yup.array().of(
        Yup.object({
          name: Yup.string().required(t('Required')),
          description: Yup.string(),
        }),
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const modifiedValues = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== formik.initialValues[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      const body = parseQuestionnaireValuesForPost(modifiedValues, data.questionnaire.id);

      updateQuestionnaire({ id, body })
        .then(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (data && data.recurrences !== '') {
      setShowRecurrence(!!data.recurrences);
    }
  }, [data]);


  return (
    <Box width="100%">
      {/* Status Section */}
      <Box mb={2}>
        <Typography variant="h6">{t('Status')}</Typography>
        <FormControlLabel
          control={<Switch disabled={readOnly} checked={formik.values.active} onChange={formik.handleChange} name="active" />}
          label={formik.values.active ? t('Active') : t('Inactive')}
        />
      </Box>
      <Divider />

      {/* Name and Description Section */}
      <Box mb={2} mt={2}>
        <Typography variant="h6">{t('Name and Description')}</Typography>
        <LocaleWrapper name="name">
          <FormTextField disabled={readOnly} name="name" formik={formik} label={t('Name')} sx={{ width: '100%' }} />
        </LocaleWrapper>
        <LocaleWrapper name="description">
          <RichFormText formik={formik} name="description" label={t('Description')} readOnly={readOnly} />
        </LocaleWrapper>
      </Box>
      <Divider />

      {/* Form Submission Section */}
      <form onSubmit={formik.handleSubmit}>
        {/* Options Section */}
        <Box mb={2} mt={2}>
          <Typography variant="h6">{t('Options')}</Typography>
          <Row mb={2}>
            <FormControlLabel
              sx={{ mx: 1 }}
              control={<Checkbox disabled={readOnly} checked={formik.values.on_demand} onChange={formik.handleChange} name="on_demand" />}
              label={t('On Demand')}
            />
            <FormControlLabel
              sx={{ mx: 1 }}
              control={<Checkbox disabled={readOnly} checked={formik.values.is_dashboard} onChange={formik.handleChange} name="is_dashboard" />}
              label={t('Show on Dashboard')}
            />
          </Row>
        </Box>
        <Divider />

        {/* Type and Score Type Section */}
        <Box mb={2} mt={2}>
          <Typography variant="h6">{t('Type and Score Type')}</Typography>
          <Row mb={2}>
            <FormSelect sx={{ mx: 1 }} disabled={readOnly} formik={formik} name="type" label={t('Type')}>
              <MenuItem value="standard">{t('Standard')}</MenuItem>
              <MenuItem value="logbook">{t('Logbook')}</MenuItem>
            </FormSelect>
            <FormSelect sx={{ mx: 1 }} disabled={readOnly} formik={formik} name="score_type" label={t('Score Type')}>
              <MenuItem value="none">{t('None')}</MenuItem>
              <MenuItem value="sum">{t('Sum')}</MenuItem>
              <MenuItem value="ahp">{t('Analytic Hierarchy Process')}</MenuItem>
            </FormSelect>
            <FormTextField
              disabled={formik.values.score_type !== 'sum' || readOnly}
              name="max_score"
              formik={formik}
              label={t('Max Score')}
              sx={{
                mx: 1,
                ...(formik.values.score_type !== 'sum' && {
                  backgroundColor: '#f5f5f5',
                  cursor: 'not-allowed',
                }),
              }}
            />
          </Row>
        </Box>
        {/* Alert Threshold Section */}
        {formik.values.score_type === 'sum' && (
          <Box mb={2} mt={2}>
            <Card sx={{ minWidth: 300 }}>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                  <Typography variant="h6">{t('Alert Threshold')}</Typography>
                  <Button onClick={() => setAdvancedMode(!advancedMode)} size="small" variant="text">
                    {advancedMode ? t('Basic') : t('Advanced')}
                  </Button>
                </Box>
                <Divider />
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={6}>
                    <ThresholdTextField
                      name="min_high_threshold"
                      label={advancedMode ? t('High Min') : t('Min')}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ThresholdTextField
                      name="max_high_threshold"
                      label={advancedMode ? t('High Max') : t('Max')}
                      formik={formik}
                    />
                  </Grid>
                  {advancedMode && (
                    <>
                      <Grid item xs={6}>
                        <ThresholdTextField name="min_mod_threshold" label={t('Moderate Min')} formik={formik} />
                      </Grid>
                      <Grid item xs={6}>
                        <ThresholdTextField name="max_mod_threshold" label={t('Moderate Max')} formik={formik} />
                      </Grid>
                      <Grid item xs={6}>
                        <ThresholdTextField name="min_low_threshold" label={t('Low Min')} formik={formik} />
                      </Grid>
                      <Grid item xs={6}>
                        <ThresholdTextField name="max_low_threshold" label={t('Low Max')} formik={formik} />
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}
        <Divider />

        {/* Roles and Diagnosis Section */}
        <Box mb={2} mt={2}>
          <Typography variant="h6">{t('Roles and Diagnosis')}</Typography>
          <Row mb={2}>
            <RoleTypes
              formik={formik}
              disabled={readOnly}
              name="role_acc_type"
            />
            <Diagnosis
              formik={formik}
              label={t('Diagnosis')}
              name="diagnoses"
            />
          </Row>
        </Box>
        <Divider />

        {/* Recurrence Section */}
        <Box mb={2} mt={2}>
          <Typography variant="h6">{t('Recurrence')}</Typography>
          <Button
            sx={{ ml: 1, mb: 1 }}
            disabled={readOnly}
            onClick={() => {
              setShowRecurrence(!showRecurrence);
              formik.setFieldValue('recurrences', !showRecurrence ? data.recurrences : '');
            }}
            variant="outlined"
          >
            {showRecurrence ? t('Remove recurrence') : t('Add recurrence')}
          </Button>
          {showRecurrence && (
            <Box border={(theme) => `1px solid ${theme.palette.grey[300]}`} borderRadius={2} p={1}>
              <Recurrence
                onChange={(recurrence)=>formik.setFieldValue('recurrences', recurrence)}
                rule={formik.values.recurrences}
              />
            </Box>
          )}
        </Box>
        <Divider />

        {/* Action Buttons Section */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            color="error"
            variant="contained"
            disabled={readOnly}
            onClick={() => setDeleteOpen(true)}
          >
            {t('Delete')}
          </Button>
          <LoadingButton
            variant="contained"
            loading={formik.isSubmitting}
            sx={{ ml: 1 }}
            type="submit"
            disabled={!formik.dirty}
          >
            {t('Save')}
          </LoadingButton>
        </Box>
      </form>

      {/* Delete Dialog */}
      <DeleteDialog open={deleteOpen} handleClose={() => setDeleteOpen(false)} deleteItem={handleDelete} />
    </Box>
  );
};

export default QuestionnaireEdit;
