import {
  PersonAddAlt1, ArrowDropDown, Cancel, Loop,
} from '@mui/icons-material/';
import {
  Box, Button, ButtonGroup, InputAdornment, Menu,
  MenuItem, TextField, Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DashboardTable from '../components/dashboard/DashboardTable';
import FilterButton from '../components/FilterButton';
import { greyboxApiActions } from '../redux/api';
import AccountCreationDialog from '../components/accounts/AccountCreationDialog';
import { useDebounce } from 'use-debounce';

const useStyles = makeStyles((theme) => ({
  loop: {
    animation: `$rotation 1000ms ${theme.transitions.easing.easeInOut}`,
  },
  cancelButton: {
    transform: 'scale(0.8)',
    cursor: 'pointer',
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
}));

const Dashboard = () => {
  const { medicalActType } = greyboxApiActions;
  const { t } = useTranslation();
  const classes = useStyles();
  const { access } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
  const [anchorEl, setAnchorEl] = useState(null);
  const [refreshMenuOpen, setRefreshMenuOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState('');
  const [debouncedValue] = useDebounce(query, 300);
  const [selectedTimer, setSelectedTimer] = useState(parseInt(localStorage.getItem('timer'), 10) || 2);
  const [loopAnimation, setLoopAnimation] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filters, setFilters] = useState([]);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);

  const timerChoices = [
    {
      value: null,
      label: t('None'),
    },
    {
      value: 30000,
      label: '30s',
    },
    {
      value: 5 * 60000,
      label: '5m',
    },
    {
      value: 30 * 60000,
      label: '30m',
    },
    {
      value: 60 * 60000,
      label: '1h',
    },
  ];

  medicalActType.get(null, { skip: access === 'P' });

  useEffect(() => {
    const { value } = timerChoices[selectedTimer];
    const interval = setInterval(() => {
      setRefresh(true);
      setLoopAnimation(classes.loop);
    }, value || 10000);

    if (!value) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [selectedTimer]);

  const handleMenuClick = (e) => {
    setAnchorEl(null);
    setRefreshMenuOpen(false);
    localStorage.setItem('timer', e.target.value);
    setSelectedTimer(e.target.value);
  };

  const handleRefreshClick = () => {
    setLoopAnimation(classes.loop);
    setRefresh(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      setQuery('');
    }
  };

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Box sx={{ display: 'flex' }} data-cy="dashboard">
          {!isMobile && (
            <>
              <TextField
                placeholder={`${count} ${t('patients')}...`}
                value={query}
                variant="outlined"
                sx={{
                  mr: 1,
                  minWidth: '150px',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 2,
                }}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleKeyPress}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {query !== '' && (
                        <Cancel
                          className={classes.cancelButton}
                          onClick={() => setQuery('')}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <ButtonGroup
                elevation={0}
                sx={{ backgroundColor: theme.palette.background.paper }}
                size="small"
              >
                <Tooltip title={t('Refresh')} enterDelay={500} enterNextDelay={500}>
                  <Button
                    onClick={handleRefreshClick}
                    disabled={Boolean(loopAnimation)}
                  >
                    <Loop
                      className={loopAnimation}
                      onAnimationEnd={() => setLoopAnimation(null)}
                    />
                  </Button>
                </Tooltip>
                <Tooltip title={t('Set refresh interval')} placement="right" enterDelay={500} enterNextDelay={500}>
                  <Button
                    className={classes.btn}
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setRefreshMenuOpen(true);
                    }}
                  >
                    {timerChoices[selectedTimer].value ? timerChoices[selectedTimer].label : ''}
                    <ArrowDropDown />
                  </Button>
                </Tooltip>
                {refreshMenuOpen && (
                  <Menu
                    anchorEl={anchorEl}
                    variant="menu"
                    open={refreshMenuOpen}
                    onClose={() => {
                      setRefreshMenuOpen(false);
                      setAnchorEl(null);
                    }}
                  >
                    {timerChoices.map((r, idx) => (
                      <MenuItem
                        key={idx}
                        value={idx}
                        onClick={handleMenuClick}
                        selected={idx === selectedTimer}
                      >
                        {r.label}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              </ButtonGroup>
              <FilterButton filters={filters} setFilters={setFilters} showCareTeams />
              {clinic && clinic.permissions.includes('register_account') && (<Button
                onClick={() => setOpen(true)}
                startIcon={<PersonAddAlt1 />}
                variant="contained"
                color="primary"
                sx={{ ml: 'auto' }}
              >
                {t('Add patient')}
              </Button>)}
            </>
          )}
        </Box>
        <DashboardTable
          refresh={refresh}
          handleRefresh={setRefresh}
          query={debouncedValue}
          setCount={setCount}
          count={count}
          filters={filters}
        />
      </Box>
      <AccountCreationDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default Dashboard;
