import React from 'react';
import {
  Box, Button, Paper, Skeleton, Typography,
} from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import RegisterMedicalActDialog from './RegisterMedicalActDialog';

const MedicalActSummary = ({ patientUuid }) => {
  const { t } = useTranslation();

  const { medicalAct, account } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);

  // We only fetch the most recent medical act (page_size=1).
  const { data = {}, isFetching } = medicalAct.list({
    patient: patientUuid,
    page_size: 1,
    clinic: clinic?.id,
  });

  // Extract info from the most recent medical act if available.
  const hasData = data.count > 0;
  const latestAct = hasData ? data.results[0] : null;
  const { hcp: hcpUuid } = latestAct || {};

  // Fetch HCP details using the HCP uuid from the latest act.
  const accountSelector = account.get(hcpUuid, { skip: !hcpUuid });

  const [open, setOpen] = React.useState(false);

  // Early return for loading state
  if (isFetching || accountSelector.isFetching) {
    return (
      <Paper>
        <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="40%" />
          <Skeleton variant="text" width="50%" />
        </Box>
      </Paper>
    );
  }

  return (
    <>
      <Paper>
        <Box display="flex" flexDirection="column" alignItems="center" padding={1}>
          <Typography variant="subtitle1" align="center" style={{ width: '100%' }}>
            {hasData ? t('Latest follow-up') : t('No recorded follow-up yet')}
          </Typography>

          {hasData && (
            <>
              <Typography>
                <strong>{latestAct?.medical_act_type_name}</strong>
              </Typography>
              <Typography variant="caption">
                {t('By')}{' '}
                {accountSelector.data?.firstName} {accountSelector.data?.lastName}
              </Typography>
              <Typography variant="caption">
                {moment(latestAct?.createdDate).format('lll')}
              </Typography>
            </>
          )}

          <Box mt={1} width="100%">
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setOpen(true)}
            >
              {t('Record a follow-up')}
            </Button>
          </Box>
        </Box>
      </Paper>

      {open && (
        <RegisterMedicalActDialog
          patientUuid={patientUuid}
          open={open}
          handleClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default MedicalActSummary;
