import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Button, Dialog, DialogActions, DialogContent
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import DialogTitle from '../DialogTitle';
import Row from '../Row';
import FormTextField from '../form-inputs/FormTextField';
import PhoneNumber from '../form-inputs/PhoneNumber';

const createValidation = (t) => {
  const validationSchema = yup.object().shape({
    email: yup.string().nullable().email(t('Invalid email')),
    phoneNumber: yup.string().nullable(),
  });

  return validationSchema;
};

const PatientLinkInvitation = ({ patient, open, handleClose }) => {
  const { t } = useTranslation();
  const { invitation } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sendInvitation] = invitation.add();

  const formik = useFormik({
    initialValues: {
      email: patient?.email || '',
      phoneNumber: patient?.phoneNumber || '',
    },
    enableReinitialize: true,
    validationSchema: createValidation(t),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (!patient) {
        setErrorMessage(t('Patient data is not available.'));
        formik.setSubmitting(false);
        return;
      }

      setErrorMessage('');
      values.clinic = clinic.id;
      values.account = patient.uuid;

      sendInvitation({ body: values, feedback: { success: t('Invitation sent') } }).unwrap().then(() => {
        formik.setSubmitting(false);
        formik.resetForm();

        if (redirect) {
          handleClose();
        }
      }).catch((error) => {
        formik.setSubmitting(false);
        if (error.status === 400 && error.data) {
          const errors = error.data;
          if (errors.error) {
            setErrorMessage(errors.error);
          }
          formik.setErrors(errors);
        }
      });
    },
  });

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        {t('Send invitation')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
          <Row>
            <FormTextField formik={formik} name="email" label={t('Email')} />
            <PhoneNumber formik={formik} name="phoneNumber" />
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('Cancel')}
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => setRedirect(true)}
            sx={{ ml: 'auto' }}
            loading={formik.isSubmitting}
          >
            {t('Send')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PatientLinkInvitation;
