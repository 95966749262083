import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import { setTempAuthorization } from '../redux/authorization';

const AuthenticateForQuestionnaire = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const id = params.get('id');
  const layoutMode = params.get('layoutMode');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const targetUrl = layoutMode
        ? `/complete-questionnaire/${id}?layoutMode=${layoutMode}`
        : `/complete-questionnaire/${id}`;
      
      dispatch(setTempAuthorization({ token: `Token ${token}`, authState: 'questionnaire' }));
      navigate(targetUrl);
    }
  }, []);

  return null;
};

export default AuthenticateForQuestionnaire;
