import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { greyboxApiActions } from '../../redux/api';
import React from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const Identifiers = ({ informations, clinic, role, identifiers: initialIdentifiers }) => {
  const { t } = useTranslation();
  const { identifierConfig } = greyboxApiActions;
  const { data } = identifierConfig.list({
    clinic: clinic?.id,
    reference_fhir_fields: role === 'PT' ? ['Practitioner.identifier'] : ['Patient.identifier'],
  });
  const identifiers = data || initialIdentifiers;

  Identifiers.validationSchema = Yup.array().of(
    Yup.object().shape({
      value: Yup.string()
        .required(t('Required'))
        .test('matches-regex', t('Invalid value format'), function (value) {
          const { path, parent } = this;

          if (!parent.system) {
            return this.createError({
              path,
              message: t('System is required'),
            });
          }

          const selectedIdentifier = identifiers?.find(
            (identifier) =>
              identifier.system === parent.system &&
              parent.type.coding.some((coding) => coding.code === identifier.code)
          );

          if (!selectedIdentifier) {
            return this.createError({
              path,
              message: t('Invalid system identifier'),
            });
          }

          const regex = selectedIdentifier.regex ? new RegExp(selectedIdentifier.regex) : null;
          if (regex && !regex.test(value)) {
            return this.createError({
              path,
              message: t('Invalid value format'),
            });
          }

          return true;
        }),
      system: Yup.string().required(t('Required')),
      type: Yup.object().shape({
        coding: Yup.array().of(
          Yup.object().shape({
            system: Yup.string().required(t('Required')),
            code: Yup.string().required(t('Required')),
          })
        ),
      }),
    })
  );

  const handleIdentifierDropdownChange = (index, identifierObject) => {
    const updatedIdentifiers = [...informations.values.identifier];
    updatedIdentifiers[index] = {
      ...updatedIdentifiers[index],
      system: identifierObject.system,
      value: '',
      type: {
        coding: [
          {
            system: identifierObject.system,
            code: identifierObject.code,
          },
        ],
      },
    };
    informations.setFieldValue('identifier', updatedIdentifiers);
  };

  const handleIdentifierValueChange = (index, field, value) => {
    const updatedValue =
      field === 'value' && typeof value === 'string' ? value.replace(/[a-z]/g, (match) => match.toUpperCase()) : value;

    const updatedIdentifiers = [...informations.values.identifier];
    updatedIdentifiers[index] = {
      ...updatedIdentifiers[index],
      [field]: updatedValue,
    };

    informations.setFieldValue('identifier', updatedIdentifiers);
  };

  const handleRemoveIdentifier = (index) => {
    const updatedIdentifiers = informations.values.identifier.slice();
    updatedIdentifiers.splice(index, 1);
    informations.setFieldValue('identifier', updatedIdentifiers);
  };

  return (
    <FormControl sx={{ width: '100%', mt: 2 }}>
      <Box sx={{ mx: 1, display: 'flex', alignItems: 'center' }}>
        <FormLabel>{t('Identifiers')}</FormLabel>
        <IconButton
          size="small"
          sx={{ ml: 1 }}
          onClick={() => {
            const updatedIdentifiers = [...informations.values.identifier];
            updatedIdentifiers.push({
              system: '',
              value: '',
              type: {
                coding: [
                  {
                    system: '',
                    code: '',
                  },
                ],
              },
            });
            informations.setFieldValue('identifier', updatedIdentifiers);
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>

      {informations.values.identifier.map((id, index) => {
        const selectedIdentifier = identifiers?.find(
          (identifier) =>
            identifier.system === id.system && id.type.coding.some((coding) => coding.code === identifier.code)
        );

        return (
          <Box
            key={index}
            sx={{
              position: 'relative',
              mb: 3,
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 2,
              p: 2,
            }}
          >
            <IconButton
              aria-label="delete"
              onClick={() => handleRemoveIdentifier(index)}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
            >
              <DeleteIcon />
            </IconButton>

            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              {t('Identifier')} {index + 1}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor={`identifier-${index}`}>{t('Identifier')}</InputLabel>
                  <Select
                    id={`system-${index}`}
                    value={
                      identifiers?.find(
                        (identifier) =>
                          identifier.system === id.system &&
                          id.type.coding.some((coding) => coding.code === identifier.code)
                      ) || ''
                    }
                    onChange={(e) => {
                      const updatedIdentifier = e.target.value;
                      handleIdentifierDropdownChange(index, updatedIdentifier);
                    }}
                    label={t('Identifier')}
                  >
                    {identifiers?.map((identifier) => (
                      <MenuItem key={identifier.id} value={identifier}>
                        {identifier.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputMask
                  mask={selectedIdentifier?.mask}
                  value={id.value}
                  onChange={(e) => {
                    const updatedValue = e.target.value;
                    handleIdentifierValueChange(index, 'value', updatedValue, selectedIdentifier);
                  }}
                  onFocus={(e) => e.target.select()}
                  maskChar=""
                  inputMode="text"
                  required
                >
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label={t('ID number')}
                      placeholder={selectedIdentifier?.place_holder}
                      value={id.value}
                      error={Boolean(informations.errors?.identifier?.[index]?.value)}
                      helperText={informations.errors?.identifier?.[index]?.value}
                      fullWidth
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </FormControl>
  );
};
